































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productservice from "@/services/product-service";
import ProjectService from "@/services/project-service";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class StockList extends Vue {
  public obj: any = {};
  public currentItem: any = null;
  public currentIndex: number = -1;
  public title: string = "";
  public rows = 100;
    
  public currentPage = 1
  public items:any = []
  public master_items :any = [];
  public transfer_items :any = [];

  
  public ddl_item:any = []
  public ddl_item_code: any = {};

  public from_ddl_project:any = []
  from_ddl_project_code: any = {};

  public to_ddl_project:any = []
  to_ddl_project_code: any = {};
  
          
          public columns = [
          {
             label: 'Title',
            field: 'title',
          },
          {
             label: 'SKU',
            field: 'sku',
          },
          {
             label: 'Ware House',
            field: 'project_tile',
          },          
          {
             label: 'Qty',
            field: 'qty',
          },
           {
             label: 'Owner',
            field: 'owner',
          },
          {
             label: 'Created At',
            field: 'created_at',
          },
  ];

  refreshList() {
    this.master_items = [];
    this.to_ddl_project = [];
    this.to_ddl_project_code = {}
    this.currentItem = null;
    this.currentIndex = -1;
  }


  to_search_project(title: any){
    if(title.length > 4){
      ProjectService.getByTitle(title)
      .then((response) => {  
        this.to_ddl_project = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.to_ddl_project = [];} 
  }

   to_get_project(data: any){
    this.to_ddl_project_code = data;
    if(this.to_ddl_project_code){this.to_ddl_project = [];}
  }
  search_stock(){
    productservice.get_item_stock_project(this.to_ddl_project_code['id'])
      .then((response) => {  
        this.master_items = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
  }


  mounted() {
    // this.get_items();
    this.obj = {};
  }
}
